import { ReactNode } from 'react';

import { ProductTile } from '@/core/ui/components/ProductTile/ProductTile';
import { Product } from '@/modules/product/queries/types';

const slideClassNames = 'keen-slider__slide min-w-full';

type ProductsCarouselSliderProps = {
  products: readonly Product[];
  customSlide?: ReactNode;
  isProductTitleNotClickable?: boolean;
};

export const ProductsCarouselSlider = ({
  products,
  customSlide,
  isProductTitleNotClickable,
}: ProductsCarouselSliderProps) => (
  <>
    {products.map((product) => (
      <div key={product.id} className={slideClassNames}>
        <ProductTile
          product={product}
          isNotClickable={isProductTitleNotClickable}
        />
      </div>
    ))}
    {customSlide && <div className={slideClassNames}>{customSlide}</div>}
  </>
);
